import * as React from "react";
import { Typography } from "@material-ui/core";

const Evapotranspiration: React.SFC = () => {
    return (
        <div style={{ height: "400px", padding: "100px" }}>
            <Typography>This area is under maintenance!</Typography>
        </div>
    );
};

export default Evapotranspiration;
